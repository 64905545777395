import './GlobalErrorScreen.scss';

import { Capacitor } from '@capacitor/core';
import { WarningCircle } from '@phosphor-icons/react';
import { ReactNode, useState } from 'react';

import EnvironmentPicker from '../../../../pages/DeveloperOptions/components/EnvironmentPicker';
import { Layout } from '../../../Layout';
import GlobalErrorEnvironmentPickerButton from '../GlobalErrorEnvironmentPickerButton/GlobalErrorEnvironmentPickerButton';

const className = 'c-GlobalErrorScreen';

const GlobalErrorScreen = ({
  message,
  header,
  button,
}: {
  message: ReactNode;
  header: ReactNode;
  button: ReactNode;
}) => {
  const [clickCounter, setClickCounter] = useState(0);
  return (
    <>
      <GlobalErrorEnvironmentPickerButton
        onClick={() => setClickCounter((counter) => counter + 1)}
      />
      <Layout centered showNavBar={false}>
        <div className={className}>
          {Capacitor.isNativePlatform() && clickCounter >= 10 ? (
            <EnvironmentPicker />
          ) : null}
          <div className={`${className}__icon`}>
            <WarningCircle size={64} weight="bold" />
          </div>
          <div className={`${className}__title`}>{header}</div>
          <div className={`${className}__text`}>{message}</div>
          {button}
        </div>
      </Layout>
    </>
  );
};

export default GlobalErrorScreen;
