import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Button, ConfigLoadError } from '@travelwin/core';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import { ApiCompatibilityError } from '../../services/helpers/eCommerceVersionCheck';
import GlobalErrorScreen from './components/GlobalErrorScreen/GlobalErrorScreen';

const GlobalErrorBoundaryFallback = ({
  errorData: { error },
}: {
  errorData: { error: Error };
}) => {
  const isClientSide = typeof window !== 'undefined';

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide();
      StatusBar.setStyle({ style: Style.Light });
    }
  }, []);

  if (error instanceof ApiCompatibilityError) {
    return (
      <GlobalErrorScreen
        header={
          <Trans
            i18nKey="global_error_page.version_invalid_header"
            defaults={'Please update the app'}
          />
        }
        message={
          <Trans
            i18nKey="global_error_page.version_invalid_message"
            defaults={
              'An important update is available and required to continue'
            }
          />
        }
        button={
          Capacitor.isNativePlatform() ? (
            <Button
              data-testid="button-update-app"
              fluid
              onClick={() => AppUpdate.openAppStore()}
            >
              {
                <Trans
                  i18nKey="global_error_page.update_button_label"
                  defaults={'Check for updates'}
                />
              }
            </Button>
          ) : (
            <Button
              data-testid="button-update-app"
              fluid
              onClick={() => {
                location.href = '/';
              }}
            >
              {<Trans i18nKey="label.refresh_page" defaults={'Refresh Page'} />}
            </Button>
          )
        }
      />
    );
  }

  if (isClientSide && !navigator?.onLine) {
    return (
      <GlobalErrorScreen
        header={
          <Trans
            i18nKey="global_error_page.offline_header"
            defaults={'Are you online?'}
          />
        }
        message={
          <Trans
            i18nKey="global_error_page.offline_message"
            defaults={`We can't seem to connect. Please check that you have a working Internet connection and try again below.`}
          />
        }
        button={
          <Button fluid onClick={() => window.location.reload()}>
            {
              <Trans
                i18nKey="global_error_page.refresh_button_label"
                defaults={'Try again'}
              />
            }
          </Button>
        }
      />
    );
  }

  if (error instanceof ConfigLoadError) {
    return (
      <GlobalErrorScreen
        header={
          <Trans
            i18nKey="global_error_page.config_header"
            defaults={'We can’t reach the Shop right now.'}
          />
        }
        message={
          <>
            <p>
              {
                <Trans
                  i18nKey="global_error_page.config_message_line1"
                  defaults={
                    'There seems to be an issue connecting, the store may be temporarily down.'
                  }
                />
              }
            </p>
            <p>
              {
                <Trans
                  i18nKey="global_error_page.config_message_line2"
                  defaults={'Try checking again in a moment.'}
                />
              }
            </p>
          </>
        }
        button={
          <Button fluid onClick={() => window.location.reload()}>
            {
              <Trans
                i18nKey="global_error_page.config_button_label"
                defaults={'Try again'}
              />
            }
          </Button>
        }
      />
    );
  }

  return (
    <GlobalErrorScreen
      header={
        <Trans
          i18nKey="error.something_went_wrong"
          defaults={'Something went wrong.'}
        />
      }
      message={error.message}
      button={
        <Button fluid onClick={() => window.location.reload()}>
          {
            <Trans
              i18nKey="global_error_page.refresh_button_label"
              defaults={'Try again'}
            />
          }
        </Button>
      }
    />
  );
};

export default GlobalErrorBoundaryFallback;
