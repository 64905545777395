import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Amplify } from 'aws-amplify';

import { isRemix } from './utils/ssrUtils';

const isNativePlatform = Capacitor.isNativePlatform();

type AuthConfig = {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oauthDomain?: string;
};

const nativeRedirectUrl = 'com.simlocal.esim.travel:/';

/*
  `@aws-amplify/auth` uses `history.replaceState()` to dispose of the OAuth redirection params (e.g. `?code`)
  by replacing the current url with `redirectSignIn` url which works fine on the web but throws an error on ios/android.
  Since there are open issues that reference that (e.g. https://github.com/aws-amplify/amplify-js/issues/3537) but there are no clean fixes
  to this problem we hijack `history.replaceState` and add our custom implementation that delegates to the original browser method
  but adjust the behaviour for this one, special use-case.
  
  Link to `@aws-amplify/auth` source code where `history.replaceState` is used:
  https://github.com/aws-amplify/amplify-js/blob/d2b7ae4ddcbdbc18a7bc033172e3b713766d63f7/packages/auth/src/Auth.ts#L2554
 */
if (typeof window !== 'undefined') {
  const replaceState = window.history.replaceState.bind(window.history);

  window.history.replaceState = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    unused: string,
    url?: string | URL | null,
  ) => {
    if (url === nativeRedirectUrl) {
      return replaceState(data, unused, window.location.origin);
    }

    return replaceState(data, unused, url);
  };
}

/**
 * Custom url opener that opens OAuth sign in/out pages in in-app ios/android browser using
 * https://capacitorjs.com/docs/apis/browser
 *
 * The redirection back to the app is handled in the App component's `appUrlOpen` event handler.
 */
const capacitorUrlOpener = (url: string) => {
  return Browser.open({ url, windowName: '_self' });
};

export const configureAmplify = ({
  region,
  userPoolId,
  userPoolWebClientId,
  oauthDomain,
}: AuthConfig) => {
  Amplify.configure({
    region,
    userPoolId,
    userPoolWebClientId,
    // Enforce user authentication prior to accessing AWS resources
    mandatorySignIn: false,
    storage: isRemix() ? undefined : window.localStorage,
    ssr: isRemix(),
    ...(oauthDomain
      ? {
          oauth: {
            domain: oauthDomain,
            responseType: 'code',
            scope: [
              'profile',
              'openid',
              'email',
              'aws.cognito.signin.user.admin',
            ],
            ...(isNativePlatform
              ? {
                  redirectSignIn: nativeRedirectUrl,
                  redirectSignOut: nativeRedirectUrl,
                  urlOpener: capacitorUrlOpener,
                }
              : {
                  redirectSignIn:
                    typeof window === 'undefined'
                      ? undefined
                      : window.location.origin,
                  redirectSignOut:
                    typeof window === 'undefined'
                      ? undefined
                      : window.location.origin,
                }),
          },
        }
      : {}),
  });
};
