import './EnvironmentPicker.scss';

import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { DEV_API_ENVIRONMENT, ENVIRONMENTS } from '../../../../constants';

const className = 'c-EnvironmentPicker';

const EnvironmentPicker = () => {
  const { t } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem(DEV_API_ENVIRONMENT, event.target.value);

    window.location.reload();
  };

  const getDefaultApiEnv = () => {
    return (
      Object.values(ENVIRONMENTS).find((environment) => {
        return import.meta.env.REACT_APP_API_ENDPOINT_ECOMMERCE?.includes(
          environment.toLowerCase(),
        );
      }) || ENVIRONMENTS.PROD
    );
  };

  return (
    <div className={className}>
      <span className={`${className}__label`}>
        {t('developer_options.selectEnvironmentLabel', 'Environment')}
      </span>
      <select
        className={`${className}__select`}
        data-testid="select-env-picker"
        onChange={onChange}
        defaultValue={
          localStorage.getItem(DEV_API_ENVIRONMENT) || getDefaultApiEnv()
        }
      >
        <option value={''}></option>
        {Object.keys(ENVIRONMENTS).map((env) => (
          <option
            key={env}
            value={ENVIRONMENTS[env as keyof typeof ENVIRONMENTS]}
          >
            {env}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EnvironmentPicker;
