import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@travelwin/core/src/components/RegionFlags/RegionFlags.scss';
import '@travelwin/core/src/styles/variables.css';
import '@/index.scss';

import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { LoaderFunctionArgs } from '@remix-run/node';
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  getBrandFontsUrl,
  getBrandVariables,
  MockConfigProvider,
  setDefaultDateFnsLocale,
  SUPPORTED_LOCALE,
} from '@travelwin/core';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';

import { configureAmplify } from '@/amplify.config';
import { AnalyticsProvider } from '@/analytics';
import GlobalErrorBoundaryFallback from '@/components/GlobalErrorBoundaryFallback/GlobalErrorBoundaryFallback';
import { MetaPixelTracker } from '@/components/MetaPixel';
import { TikTokPixelTracker } from '@/components/TikTokPixel';
import { ConnectionProvider } from '@/context/Connection';
import { ConsentProvider } from '@/context/Consent';
import { CurrencyProvider } from '@/context/Currency';
import EuiccManager from '@/context/EuiccManager';
import { UserProvider } from '@/context/User';
import { isNotServerSideError } from '@/monitoring/remixSentry';
import { ShopBrand } from '@/utils/brandUtils';
import { getConfig, getCurrencyCookie, getUser } from '@/utils/ssrUtils';

import i18n from './i18n';
import i18next from './i18next.server';

// https://remix.run/docs/en/main/guides/envvars#browser-environment-variables
const getEnv = () => {
  const { REACT_APP_API_ENDPOINT_ECOMMERCE, REACT_APP_CMS_ENDPOINT } =
    process.env;

  return {
    REACT_APP_API_ENDPOINT_ECOMMERCE,
    REACT_APP_CMS_ENDPOINT,
  };
};

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  if (
    params.lang &&
    (params.lang === 'en' || !i18n.supportedLngs.includes(params.lang))
  ) {
    throw new Response(`Not Found: Invalid language ${params.lang}`, {
      status: 404,
    });
  }

  const env = getEnv();
  const config = await getConfig();
  configureAmplify({
    region: config.COGNITO_REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauthDomain: config.COGNITO_OAUTH_DOMAIN,
  });
  const locale = await i18next.getLocale(request);
  setDefaultDateFnsLocale(locale as SUPPORTED_LOCALE);

  const user = await getUser(request);

  const currency = getCurrencyCookie(request.headers.get('Cookie'));

  return json({
    env,
    config,
    locale,
    user,
    currency,
  });
};

export type RootLoader = typeof loader;

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  i18n: ['ecommerce', 'countries'],
};

const BrandFontLinks = ({ brand }: { brand: ShopBrand }) => {
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link rel="stylesheet" href={getBrandFontsUrl(brand)} />
    </>
  );
};

const getBrandStyles = (brand: ShopBrand) => {
  return Object.fromEntries(
    Object.entries(getBrandVariables(brand)).map(([property, value]) => [
      `--${property}`,
      value,
    ]),
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const brand = data?.config.WHITELABEL_BRAND;
  const brandStyles = brand ? getBrandStyles(brand) : {};

  const { i18n } = useTranslation();
  const locale = data?.locale ?? 'en';

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()} style={{ ...brandStyles }}>
      <head>
        <meta charSet="utf-8" />
        {Capacitor.isNativePlatform() ? (
          <meta
            name="viewport"
            content="width=device-width, shrink-to-fit=no, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
          />
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        )}
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" href="/favicon-32x32.png" type="image/png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        {brand ? <BrandFontLinks brand={brand} /> : null}
        <meta name="theme-color" content="#ffffff" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <div id="modal" />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.env)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const { config, user, locale, currency } = useLoaderData<typeof loader>();

  useState(() => {
    if (typeof window !== 'undefined') {
      configureAmplify({
        region: config.COGNITO_REGION,
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolWebClientId: config.COGNITO_USER_POOL_WEB_CLIENT_ID,
        oauthDomain: config.COGNITO_OAUTH_DOMAIN,
      });
      setDefaultDateFnsLocale(locale as SUPPORTED_LOCALE);
    }
  });

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
  );

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide();
      StatusBar.setStyle({ style: Style.Light });
    }
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <MockConfigProvider config={config}>
          <ConsentProvider>
            <AnalyticsProvider>
              <EuiccManager>
                <UserProvider initCognitoUser={user ?? undefined}>
                  <CurrencyProvider ssrCurrency={currency}>
                    <ConnectionProvider>
                      <TikTokPixelTracker />
                      <MetaPixelTracker />
                      <Outlet />
                    </ConnectionProvider>
                  </CurrencyProvider>
                </UserProvider>
              </EuiccManager>
            </AnalyticsProvider>
          </ConsentProvider>
        </MockConfigProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;

export function ErrorBoundary() {
  const routeError = useRouteError();

  const castError = routeError as { message: string; stack: string };
  if (isNotServerSideError(castError.message)) {
    captureRemixErrorBoundaryError(routeError);
  }

  console.error(routeError);

  const error = new Error(castError.message);
  return <GlobalErrorBoundaryFallback errorData={{ error }} />;
}
