import './GlobalErrorEnvironmentPickerButton.scss';

const className = 'c-GlobalErrorEnvironmentPickerButton';

function GlobalErrorEnvironmentPickerButton({
  onClick,
}: {
  onClick: () => void;
}) {
  return <div className={className} onClick={onClick}></div>;
}

export default GlobalErrorEnvironmentPickerButton;
